import { useApi } from "@/use/api/useApi";
import { generateId } from "@/utils/uniqueGenerator/generator";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { useExcelDownloader } from "@/use/other/useExcelDownloader";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import type { Ref } from "vue";

enum nameFirst {
  'lots' = 'Аналитика по закупкам',
  'customers' = 'Аналитика по заказчикам',
  'suppliers' = 'Аналитика по поставщикам',
  'won' = 'Аналитика по победам',
  'participated' = 'Аналитика по участиям',
  'competitors' = 'Аналитика по конкурентам',
  'actual' = 'Аналитика по актуальным закупкам',
  'completed' = 'Аналитика по завершенным закупкам',
  'participants' = 'Аналитика по участникам',
}

enum nameSecond {
  'industry' = ' отрасли',
  'suppliers' = ' поставщика',
  'customers' = ' заказчика',
}

function getDownloadFunction(mode: string, additionalMode?: string) : ((p: any) => Promise<any>) | undefined {
  switch (mode) {
    case 'search': return useApi().excel.downloadSearchData;
    case 'templates': return useApi().excel.downloadTemplatesData;
    case 'apps': return useApi().excel.downloadAppsData;
    case 'contracts': return useApi().excel.downloadContractsData;

    case 'industry': {
      if (additionalMode === "lots") return useApi().excel.downloadIndustryLotsData;
      if (additionalMode === "suppliers") return useApi().excel.downloadIndustrySuppliersData;
      if (additionalMode === "customers") return useApi().excel.downloadIndustryCustomersData;
      return undefined;
    }

    case 'suppliers': {
      if (additionalMode === "won") return useApi().excel.downloadSuppliersWonData;
      if (additionalMode === "participated") return useApi().excel.downloadSuppliersParticipatedData;
      if (additionalMode === "customers") return useApi().excel.downloadSuppliersCustomersData;
      if (additionalMode === "competitors") return useApi().excel.downloadSuppliersCompetitorsData;
      return undefined;
    }

    case 'customers': {
      if (additionalMode === "actual") return useApi().excel.downloadCustomersActualData;
      if (additionalMode === "completed") return useApi().excel.downloadCustomersCompletedData;
      if (additionalMode === "participants") return useApi().excel.downloadCustomersParticipantsData;
      if (additionalMode === "suppliers") return useApi().excel.downloadCustomersSuppliersData;
      return undefined;
    }

    default: return undefined;
  }
}

/** Скачивание результатов аналитика */
export function downloadAnalyticExcel(loader: Ref<boolean>, data: any, mode: string, tab: 'industry'|'suppliers'|'customers', title: string) {
  const fileName = nameFirst[mode] + nameSecond[tab] + ' ' + title;

  downloadFromBackend(loader, tab, data, fileName, mode);
}

/** Скачивание результатов поиска/аналитики с бека - напрямую используется в поиске, заявках и контрактах */
export function downloadFromBackend(loader: Ref<boolean>, mode: string, payload: any, fileName: string, additionalMode?: string) {
  const {
    excelFileName,
    excelDownloadLoader,
  } = useExcelDownloader();

  excelFileName.value = `${ fileName } от ${ formatDateTime(new Date(), '-') }.xlsx`

  loader.value = true
  excelDownloadLoader.value = true

  const downloadFunction = getDownloadFunction(mode, additionalMode)

  if (!downloadFunction) return

  downloadFunction(payload)
    .then((data) => {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(data)
      link.download = excelFileName.value
      link.click()
    })
    .catch(() => {
      useNotificationsStore().showError('Произошла ошибка при скачивании файла')
    })
    .finally(() => {
      loader.value = false;
      excelDownloadLoader.value = false;
    })
}

/** Скачивание формы расчета */
export function downloadFormCalculation(loader: Ref<boolean>, id: number, govRuId: string, lotNumber = -1) {

  const {
    excelFileName,
    excelDownloadLoader,
  } = useExcelDownloader();

  excelFileName.value = `Паспорт расчета №${ govRuId }${ lotNumber !== -1 ? ', лот №' + lotNumber : '' }.xlsx`;

  loader.value = true;
  excelDownloadLoader.value = true

  useApi().excel.fetchFormCalculation(id)
    .then((data) => {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(data)
      link.download = excelFileName.value
      link.click()
    })
    .catch(() => {
      useNotificationsStore().showError('Произошла ошибка при скачивании файла')
    })
    .finally(() => {
      loader.value = false;
      excelDownloadLoader.value = false;
    });
}

/** Скачивание внешних файлов */
export function downloadFromLink(link: string) {
  const frameId = generateId(5)
  const iframe = document.createElement('iframe')
  iframe.src = link;
  iframe.style.display = 'none'
  iframe.id = frameId
  document.body.appendChild(iframe)

  if (iframe.contentWindow!.document.readyState == 'complete') {
    setTimeout(() => {
      document.body.removeChild(document.getElementById(frameId)!)
    }, 10000)
  }
}
